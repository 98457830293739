import React from "react"
import FotoCulqui from "@components/pageCasosdeExito/image/imgPhotoEmpresa/culqui/FotoCulqui.png"
import FotoCulqui2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/culqui/FotoCulqui2.png"

//logo de empresas clientes
import Culqui from "@components/pageCasosdeExito/image/logoscustomer/Culqui.svg"

//iconos de modulos
import leads from "@components/pageCasosdeExito/image/iconcards/leads.svg"
import llamadas from "@components/pageCasosdeExito/image/iconcards/llamadas.svg"
import personalizacion from "@components/pageCasosdeExito/image/iconcards/personalizacion.svg"
import integracion from "@components/pageCasosdeExito/image/iconcards/integraciones.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/culqui/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/culqui/img2.svg"
import metrica3 from "@components/pageCasosdeExito/image/imgMetric/culqui/img3.svg"

const text1 = (
  <span className="">
  Culqi, la empresa peruana que revoluciona la forma de hacer pagos digitales a través de sus múltiples servicios, como Pos o pasarelas digitales, usa Beex para automatizar sus procesos de telemarketing.
  <br /> <br />
Beex se integra a los repositorios digitales de Culqi para contactar mejor con sus leads a través de llamadas automáticas. Gracias a la inteligencia de marcado telefónico automático y predictivo, Culqi logró aumentar en 8x sus ventas de mpos al mes.

  </span>
)

const text2 = (
  <span>
Culqi, compañía peruana, parte del grupo Credicorp, busca romper las barreras tradicionales y así llevar a más negocios a digitalizar uno de sus procesos más importantes, los pagos.
<br /> <br />
A través de sus diferentes productos, como Culqi Pos, Culqi Express o Culqi Online, esta empresa hace que los negocios no pierdan tiempo y esfuerzo en procesos engorrosos o manuales de pago. Por el contrario, brinda un abanico de posibilidades digitales para que cualquier negocio, desde cualquier lugar, cuenta con un ecosistema digital de pago en beneficio de ellos y de sus clientes.
<br /> <br />
Paul Vera, jefe de Telemarketing en Culqi, ha sido el punto de contacto principal para la implementación y desarrollo de las soluciones de Beex en Culqi y nos cuenta su experiencia y datos interesantes.

  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    <span style={{ zIndex: "1", position: "sticky" }}>Beex </span>
    <span
      style={{
        color: "transparent",
        display: "inline",
        boxShadow: " 0 -14px 0 #f7d731 inset",
        left: "0px",
        position: "absolute",
      }}
    >
      Beex
    </span>{" "}
    es nuestra solución integral para los procesos de ventas. Su ágil implementación y fácil uso, ha permitido que nuestros asesores puedan conectarse desde cualquier dispositivo para gestionar nuestra operación
  </p>
)


const text5 = (
  <span>
La gestión de telemarketing de Culqi se implementó en plena pandemia hace un par de años, siendo 100% cloud. El principal objetivo fue la integración automática de los leads que llegaban a sus diferentes repositorios de datos, como redes sociales, formularios web, etc. Esto se implementó sin ningún problema gracias a la potente y flexible estructura de API de Beex.
<br /> <br />
Culqi, logró integrar los datos de su CRM con Beex, permitiéndole automatizar el primer contacto por llamada, además de crear reglas de marcación progresiva y predictiva, lo que permitió mejorar la contactabilidad con sus leads, ya que se les empezó a contactar de forma automatizada cuando ellos lo solicitaban.
<br /> <br />
Luego de esta implementación y con las personalizaciones que surgieron en el camino, Culqi logró contactar de forma más inteligente con sus socios comerciales, y esto se notó en sus resultados:

  </span>
)
const text6 = (
  <span>
 “Con Beex y sus marcaciones telefónicas inteligentes integradas a nuestro CRM, hemos mejorado nuestra contactabilidad de 20% a más del 85%, lo que ha significado un incremento sustancial en nuestras ventas”
  </span>
)

const text7 = (
  <span>
Un canal de suma importancia para Culqi en su estrategia de telemarketing es WhatsApp. Ellos accedieron de forma rápida y simple al API de WhatsApp Business, solución que les ha permitido mejorar su tasa de conversión.
<br /> <br />
Actualmente, Culqi es capaz de armar estrategias omnicanales entre canal telefónico y WhatsApp, logrando mejorar su proceso de contacto, ya que, si sus leads no contestan por algún canal principal, gatillan acciones complementarias por un canal distinto, lo que ha mejorado su ratio de contactabilidad.
<br /> <br />
Además, con el API de WhatsApp Business, son capaces de realizar envíos masivos de plantillas, lo que les permite comunicar campañas, promociones o cualquier comunicación de marketing hacia sus clientes.
  </span>
)
const text8 = (
  <p>
    Al contar con Beex en la nube, Culqi logró administrar sus campañas de telemarketing de forma mas eficiente, ya que tenían todo el journey del cliente establecido, desde la concepción hasta el contacto por llamada telefónica.
    <br /> <br />
Eliminaron la necesidad de estar revisando a qué leads llamaban o no, y de importarlos para realizar llamadas manuales. Sin duda, una gestión mas eficiente del tiempo que se ganan con las automatizaciones en Beex.

  </p>
)
const text9 = (
  <span>
“Hemos conseguido una evolución total. Ahora tenemos control absoluto sobre las diferentes campañas de telemarketing, pudiendo gestionarlas de manera más inteligente y ordenada. Respetando nuestras reglas de negocio.”
  </span>
)
const text10 = (
  <p>
   No solo eso. Al ser una empresa con una fuerte cultura ágil y digital, para Culqi era importante que sus usuarios puedan conectarse desde donde sea. Por pandemia, el homeoffice también se implementó en Culqi, por lo que Beex, “100% cloud, 100% web”, fue la solución ideal para acompañarlos en esta gestión.
   <br /> <br />
Actualmente, las gestiones de los usuarios de Culqi se hacen desde desktop, tablet o móviles, sin importar el sistema operativo.

  </p>
)
const text11 = (
  <span>
 “Es de suma importancia la facilidad con la que nuestros asesores se conectan desde cualquier dispositivo, incluso celulares, lo cuál nos permite una gestión ágil y oportuna, sobre todo en estos tiempos de pandemia”
  </span>
)

const text12 = (
  <p>
  Beex, con sus diferentes soluciones, siempre tiene como premisa brindar una experiencia al cliente distinta, no solo con sus mejores SLAs, sino con el acompañamiento en todo momento a nuestros clientes, y Culqi ha experimentado esto.
  <br /> <br />
Culqi cuenta con soporte a todo nivel por los canales principales de contacto, como correo electrónico, WhatsApp y llamadas telefónicas. Esto es algo importante en la experiencia de la solución, ya que tienen respuesta inmediata a cualquier tipo de solicitud, incluyendo consultas de uso del producto.
  </p>
)

const text13 = (
 <span>
  “Se siente el respaldo en todo momento del equipo de Beex. La agilidad en sus respuestas y procesos de soporte generan confianza y esto se demuestra en nuestros resultados.”
 </span>
)

const text14 = (
  <span>
Estos son algunos de los procesos con los que nuestra plataforma ayuda a Culqi en su operación de telemarketing:

  </span>
 )

 const text15 = (
  <p style={{marginBottom: "70px"}}>
  A Beex y Culqi los unen las ganas de cambiar los procesos tradicionales de gestión, en el caso de Culqi, mejorar la experiencia de los pagos de los negocios. Y estamos muy felices de  brindar herramientas que ayuden en ese proceso.

</p>
)




const data = {
  intro: {
    image: Culqui,
    link: "https://www.youtube.com/watch?v=M51m456c-Mc",
    classlogo: "logo__clientes--culqui",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoCulqui,
    text4: "",
    title5: "Integración y automatización de leads con su CRM",
    text5: text5,
    title6: "Administración y accesibilidad como claves del éxito",
    text6: text6,
    title7: "WhatsApp Business API para telemarketing",
    text7: text7,
    fototwo: FotoCulqui2,
    title8: "Contact Center omnicanal en la nube",
    text8: text8,
    text9: text9,
    text10: text10,
    text11: text11,
    title12: "Acompañamiento y respaldo que se siente",
    text12: text12,
    text13: text13,
    text14: text14,
    text15: text15,

    metric1: metrica1,
    metric2: metrica2,
    metric3: metrica3,
  },

  cards: [
    {
      title: "Leads",
      img: leads,
      info:
        "Recepcionamos sus leads de hubs digitales, redes sociales y páginas web, para que sus teleoperadores los gestionen telefónicamente por marcaciones automáticas y predictivas.",
    },
    {
      title: "Marcaciones telefónicas",
      img: llamadas,
      info:
        "Llamadas progresivas y predictivas que disparamos automáticamente de los hubs digitales. También, recepción de llamadas para atender a sus clientes, cumpliendo sus flujos de respuestas interactivas y niveles de servicio.",
    },
    {
      title: "Integraciones",
      img: integracion,
      info:
        "Integraciones vía API Rest de Beex con sus aplicaciones internas y otras plataformas para gestión de clientes o atenciones personalizadas.",
    },
    {
      title: "Personalización",
      img: personalizacion,
      info:
        "Implementación de formularios personalizados en Beex para recepción de leads en agencias a nivel nacional, permitiendo gestionar automáticamente todas las oportunidades ingresadas vía telefónica.",
    },
  ],
}

export default data
